<template>
  <v-row>
    <v-col class="py-0" cols="12">
      <v-select
        v-model="sSelected"
        :items="dateRangeItems"
        :label="hideLabel ? undefined : $t(title)"
        :menu-props="{ offsetY: true }"
        hide-details="auto"
        v-bind="obAttrs"
        @change="onSelectRangeType"
      >
        <template #append-outer v-if="!showAll && compactCustomRange">
          <v-menu
            v-model="toogleMenu"
            :close-on-content-click="false"
            offset-y
            nudge-left="180"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                style="margin: -10px"
                color="green"
                @click="clickShowFields"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-calendar</v-icon>
              </v-btn>
            </template>

            <v-card>
              <date-range-fields
                :showFields="showFields"
                :sDateFrom="sDateFrom"
                :sDateTo="sDateTo"
                :hideFrom="hideFrom"
                :dateFrom="dateFrom"
                :dateFromMax="dateFromMax"
                :hideTo="hideTo"
                :dateTo="dateTo"
                :dateToMax="dateToMax"
                :dateToMin="dateToMin"
                :compactCustomRange="compactCustomRange"
                @updateDateFrom="updateDateFrom"
                @updateDateTo="updateDateTo"
              />
            </v-card>
          </v-menu>
        </template>
      </v-select>
    </v-col>
    <template v-if="!(hideCustomRange || compactCustomRange)">
      <date-range-fields
        :showFields="showFields"
        :sDateFrom="sDateFrom"
        :sDateTo="sDateTo"
        :hideFrom="hideFrom"
        :dateFrom="dateFrom"
        :dateFromMax="dateFromMax"
        :hideTo="hideTo"
        :dateTo="dateTo"
        :dateToMax="dateToMax"
        :dateToMin="dateToMin"
        :compactCustomRange="compactCustomRange"
        @updateDateFrom="updateDateFrom"
        @updateDateTo="updateDateTo"
      />
    </template>
  </v-row>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import FormFieldDatePicker from "@/components/form/fields/DatePicker.vue";
import DateRangeFields from "@/components/form/fields/DateRangeFields.vue";
import { defaults, filter } from "lodash";
import dayjs from "dayjs";
import { TranslateResult } from "vue-i18n";
import SlideYUpTransition from "vue2-transitions/src/Slide/SlideYUpTransition.vue";

type VSelectItem = {
  text: string | number | TranslateResult;
  value: DateRangeType;
  disabled?: boolean;
  divider?: boolean;
  header?: string;
};

type DateRangeType =
  | "today"
  | "current_month"
  | "last_month"
  | "current_year"
  | "last_year"
  | "custom"
  | "all";

@Component({
  components: { SlideYUpTransition, FormFieldDatePicker, DateRangeFields },
})
export default class DateRange extends Vue {
  @VModel({ type: Array, default: () => [] }) arDateRange!: (string | null)[];

  @Prop({ type: String, default: "date.range" }) readonly title!: string;
  @Prop(String) readonly selected!: DateRangeType;
  @Prop(Array) readonly selectTypes!: DateRangeType[];
  @Prop(Boolean) readonly hideFrom!: boolean;
  @Prop(Boolean) readonly hideTo!: boolean;
  @Prop(Boolean) readonly hideLabel!: boolean;
  @Prop(Boolean) readonly hideCustomRange!: boolean;
  @Prop(Boolean) readonly compactCustomRange!: boolean;
  @Prop(Boolean) readonly hasNullable!: boolean;
  @Prop(Boolean) readonly notAutoEmit!: boolean;

  arDateRangeOptions: VSelectItem[] = [
    { text: this.$t("all"), value: "all" },
    { text: this.$t("today"), value: "today" },
    { text: this.$t("current.month"), value: "current_month" },
    { text: this.$t("last.month"), value: "last_month" },
    { text: this.$t("current.year"), value: "current_year" },
    { text: this.$t("last.year"), value: "last_year" },
  ];

  sSelected: DateRangeType = "current_month";

  toogleAll: boolean = false;

  toogleMenu: boolean = false;

  get showAll(): boolean {
    this.toogleAll = false;
    return this.sSelected === "all";
  }

  get showFields(): boolean {
    return this.sSelected === "custom";
  }

  get dateRangeItems() {
    return this.selectTypes && this.selectTypes.length
      ? filter(this.arDateRangeOptions, (obDateItem) =>
          this.selectTypes.includes(obDateItem.value)
        )
      : this.arDateRangeOptions;
  }

  get dateFrom(): null | string {
    return this.hideFrom || !this.arDateRange.length
      ? null
      : this.arDateRange[0];
  }

  set dateFrom(sValue: string | null) {
    this.arDateRange.splice(0, 1, sValue);
  }

  get sDateFrom() {
    return this.dateFrom ? dayjs(this.dateFrom).format("L") : undefined;
  }

  get dateTo(): null | string {
    return this.hideTo || this.arDateRange.length <= 1
      ? null
      : this.arDateRange[1];
  }

  set dateTo(sValue: string | null) {
    this.arDateRange.splice(1, 1, sValue);
  }

  get sDateTo() {
    return this.dateTo ? dayjs(this.dateTo).format("L") : undefined;
  }

  /**
   * Returns max valid date for dateFrom input, based on dateTo or current date
   */
  get dateFromMax(): string {
    return this.dateTo || dayjs().format();
  }

  /**
   * Returns min valid date for dateTo input, based on dateFrom
   */
  get dateToMin() {
    return this.dateFrom;
  }

  /**
   * Validate max date for dateTo input, based on current date
   */
  get dateToMax() {
    return dayjs().format();
  }

  updateDateFrom(sValue: string) {
    this.dateFrom = sValue;
    this.$emit("input", this.arDateRange);
    this.toogleMenu = false;
  }

  updateDateTo(sValue: string) {
    this.dateTo = sValue;
    this.$emit("input", this.arDateRange);
    this.toogleMenu = false;
  }

  onSelectRangeType(sValue: DateRangeType) {
    const obDate = dayjs();
    const sFormat = "YYYY-MM-DD";
    let obDateFrom = null;
    let obDateTo = null;

    switch (sValue) {
      case "today":
        obDateFrom = obDate.clone();
        obDateTo = obDate.clone();
        break;

      case "custom":
        obDateFrom = obDate.clone().date(1);
        obDateTo = obDate.clone();
        this.toogleMenu = true;
        break;

      case "current_month":
        obDateFrom = obDate.clone().date(1);
        obDateTo = obDate.clone();
        break;

      case "last_month":
        // eslint-disable-next-line no-case-declarations
        const obDateLastMonth = obDate.subtract(1, "month");
        obDateFrom = obDateLastMonth.clone().date(1);
        obDateTo = obDateLastMonth.clone().endOf("month");
        break;

      case "current_year":
        obDateFrom = obDate.clone().startOf("year");
        obDateTo = obDate;
        break;

      case "last_year":
        // eslint-disable-next-line no-case-declarations
        const obDateLastYear = obDate.subtract(1, "year");
        obDateFrom = obDateLastYear.clone().startOf("year");
        obDateTo = obDateLastYear.clone().endOf("year");
        break;

      case "all":
        obDateFrom = null;
        obDateTo = null;
        break;
    }

    let arDates = [...this.arDateRange];

    if (obDateFrom) {
      // this.dateFrom = obDateFrom.format(sFormat);
      arDates.splice(0, 1, obDateFrom.format(sFormat));
    }

    if (obDateTo) {
      // this.dateTo = obDateTo.format(sFormat);
      arDates.splice(1, 1, obDateTo.format(sFormat));
    }

    if (!obDateFrom && !obDateTo) {
      arDates = [];
    }

    this.$emit("input", arDates);
  }

  mounted() {
    if (this.selected) {
      this.sSelected = this.selected;
    }

    if (this.hasNullable) {
      this.arDateRangeOptions.unshift({
        text: this.$t("all"),
        value: "all",
      });
      this.sSelected = "all";
    }

    if (!this.notAutoEmit) {
      this.onSelectRangeType(this.sSelected);
    }

    if (!this.hideCustomRange) {
      this.arDateRangeOptions.push({
        text: this.$t("other.dates"),
        value: "custom",
      });
    }
  }

  get obAttrs(): Record<string, any> {
    return defaults(this.$attrs, {
      dense: !this.compactCustomRange,
      outlined: true,
    });
  }

  clickShowFields() {
    this.toogleAll = true;
  }
}
</script>
